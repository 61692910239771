import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RecoverPage from './components/RecoverPage'; // Asegúrate de que este import es correcto
import HomePage from './components/HomePage'; // Asegúrate de que este import es correcto
import VerifyPage from './components/VerifyPage'

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/recover/:token" element={<RecoverPage />} />
          <Route path="/verify/:token" element={<VerifyPage />} />
        
          <Route path="/" element={<HomePage />} />
          {/* Aquí puedes añadir más rutas según sea necesario */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
