import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/RecoverPage.css'


const RecoverPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams(); // Esto extrae el token de la URL
  const [messageColor, setMessageColor] = useState('green'); // Predeterminado a blanco o el color que prefieras


  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      setMessage('Las contraseñas no coinciden.');
      return;
    }

    // Llamada a la API para restablecer la contraseña
    try {
      const response = await axios.post('https://dumbatravel-650caf84e12f.herokuapp.com/api/recoverPass', {
        token,
        newPassword
      });

      if (response.data.success) {
        setMessage('Contraseña restablecida correctamente.');
        setMessageColor('green');
      } else {
        setMessage(response.data.message);
        setMessageColor('red');
      }
    } catch (error) {
      console.error('Error al restablecer la contraseña:', error);
      setMessage('Error al intentar restablecer la contraseña.');
    }
  };

  return (
    <div className="recover-container">
      <h2 className="recover-title">Restablecer Contraseña</h2>
      {message && <p style={{ color: messageColor }}>{message}</p>}
      <div className="recover-form">
        <input
          className="recover-input"
          type="password"
          placeholder="Nueva Contraseña"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <input
          className="recover-input"
          type="password"
          placeholder="Confirmar Nueva Contraseña"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button className="recover-button" onClick={handlePasswordReset}>Restablecer Contraseña</button>
      </div>
    </div>
  );
};

export default RecoverPage;
