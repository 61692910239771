import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/VerifyPage.css'; // Asegúrate de crear este archivo CSS
import avion from '../assets/avion.png'

const VerifyPage = () => {
  const [verificationStatus, setVerificationStatus] = useState('');
  const { token } = useParams();
  const navigate = useNavigate(); // Crea la instancia de navigate

  useEffect(() => {
    verifyEmail(token);
  }, [token]);

  const verifyEmail = async () => {
    try {
      // Reemplaza 'http://tubackend.com/api/verify' con la URL de tu endpoint de verificación
      const response = await axios.post('https://dumbatravel-650caf84e12f.herokuapp.com/api/verify', { token });
      if (response.data.success) {
        setVerificationStatus('Tu correo electrónico ha sido verificado exitosamente. Ya puedes iniciar sesión.');
        
        setTimeout(() => navigate('/'), 5000); // Usa navigate para redireccionar
      } else {
        setVerificationStatus('No se pudo verificar tu correo electrónico. Por favor, intenta nuevamente o contacta soporte.');
      }
    } catch (error) {
        console.error('Error verificando el correo electrónico:', error.response ? error.response.data : error);
        setVerificationStatus('Ocurrió un error durante la verificación. Por favor, intenta nuevamente o contacta soporte.');
    }
    
  };

  return (
    <div className="verify-container">
        <div className="header-with-logo">
      <h1 className="homepage-header">DumbaTravel</h1>
      <img src={avion} alt="Logo Avión" className="avion-logo" />
    </div>
      <h1 className="title">Verificación de Correo Electrónico</h1>
      
      <p className="verify">{verificationStatus}</p>
    </div>
  );
};

export default VerifyPage;
