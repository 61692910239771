import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';
import apple from '../assets/apple.png'
import  google from'../assets/google.png'
import avion from '../assets/avion.png'

const Homepage = () => {
  return (
    <div className="homepage-container">
    <div className="header-with-logo">
      <h1 className="homepage-header">DumbaTravel</h1>
      <img src={avion} alt="Logo Avión" className="avion-logo" />
    </div>
      <p className="coming-soon">COMING SOON...</p>
      <div className="store-logos">
        
        {/*<a href="https://play.google.com/store/apps/details?id=<YOUR_APP_ID>">*/}

          <img src={google} alt="Disponible en Google Play" />
       {/* </a> */}

       {/* <a href="https://apps.apple.com/app/id<YOUR_APP_ID>">*/}
          <img src={apple}alt="Disponible en App Store" />
       {/* </a> */}
      </div>
      <nav className="homepage-nav">
        <ul>
        <li>
            <Link to="/sobre-nosotros" className="homepage-link">Sobre Nosotros</Link>
          </li>
          <li>
            <Link to="/contacto" className="homepage-link">Contacto</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Homepage;
